import axios from 'axios';
import store from '../../store';
const _axios = axios.create({
    baseURL: '/material',
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
});
_axios.interceptors.request.use(req => {
    req.headers['X-Access-Token'] = store.getters.getToken;
    return req;
});
_axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            const res = response.data;
            if (res.code === 0 || res.code === 200) {
                return res.data || res.result;
            } else if (res.code === undefined) {
                return res;
            } else {
                return Promise.reject(new Error(res.msg || res.message || '请求错误！'));
            }
        } else {
            return Promise.reject(new Error(response.message || response));
        }
    },
    err => {
        if (err.response) {
            err = err.response;
            if (err.data) {
                err = err.data;
            }
        }
        return Promise.reject(new Error(err.message || err.msg || err));
    }
);

export default _axios
