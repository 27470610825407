import axios from './axios'
import Vue from 'vue';

/**
 * @param {{subject}} params 
 */
export function getCategoryList(params) {
    return axios({
        method: 'get',
        url: '/teachingManage/teaching/list',
        params
    })
}

export function saveCategory(data) {
    return axios({
        method: 'post',
        url: '/teachingManage/teaching/save',
        data
    }).catch(err => {
        Vue.prototype.$Message.warning('[后台提示]' + err);
        throw err
    })
}

function debounce(fn, time) {
    let timmer
    return function (...params) {
        clearTimeout(timmer)
        timmer = setTimeout(() => {
            // 可以做防抖
            fn(...params)
        }, time);
    }
}

export const resortList = debounce(function (list) {
    let sortChanges = [];
    list.forEach((ele, i) => {
        if (ele.sort === i) {
            return;
        }
        ele.sort = i;
        sortChanges.push(ele);
    });
    sortChanges = sortChanges.map(({ id, parentId, sort, name }) => ({ id, parentId, sort, name }))
    return axios({
        url: '/teachingManage/teaching/saveSort',
        method: 'put',
        data: sortChanges
    }).catch(err => {
        Vue.prototype.$Message.warning('[后台提示]' + err);
        throw err
    })
}, 3000)

export function delCategory({ levelCode, id }) {
    return axios({
        url: `/teachingManage/teaching/delete/${id}`,
        method: 'delete',
        params: { levelCode }
    }).catch(err => {
        Vue.prototype.$Message.warning('[后台提示]' + err);
        throw err
    })
}
/**
 * 
 * @param {*} id 
 * @param {'up'|'down'} changeTo 
 */
export function shelfChange(id, changeTo) {
    return axios({
        url: `/teachingManage/teaching/upOrDown/${id}`,
        method: 'put',
        params: { status: changeTo }
    })
}