<template>
  <div class="flex">
    <Select v-model="pressId" placeholder="出版社" class="item" @input="gradeId='';$emit('select','')">
      <Option v-for="item in presses" :value="item.id" :key="item.id">{{ item.name }}</Option>
    </Select>
    <Select
      v-model="gradeId"
      placeholder="年级"
      v-if="pressId"
      class="item"
      @input="bookId='';$emit('select','')"
    >
      <Option v-for="item in gradeOptions" :value="item.id" :key="item.id">{{ item.name }}</Option>
    </Select>
    <Select
      v-model="bookId"
      placeholder="书"
      v-if="pressId&&gradeId"
      class="item"
      @input="volumId='';$emit('select','')"
    >
      <Option v-for="item in bookOptions" :value="item.id" :key="item.id">{{ item.name }}</Option>
    </Select>
    <!--  -->
    <Select
      v-model="volumId"
      placeholder="册"
      v-if="pressId&&gradeId&&bookId"
      class="item"
      @input="unitId='';$emit('select',unitOptions.length?'':$event)"
    >
      <Option v-for="item in volumOptions" :value="item.id" :key="item.id">{{ item.name }}</Option>
    </Select>
    <Select
      v-model="unitId"
      placeholder="教材"
      v-if="unitOptions.length&&pressId&&gradeId&&bookId&&volumId"
      class="item"
      @input="$emit('select',$event)"
    >
      <Option v-for="item in unitOptions" :value="item.id" :key="item.id">{{ item.name }}</Option>
    </Select>
  </div>
</template>

<script>
import { getCategoryList } from './services/materialService';
export default {
  data() {
    return {
      pressId: '',
      gradeId: '',
      bookId: '',
      volumId: '',
      unitId: '',

      units: [],
      volums: [],
      books: [],
      grades: [],
      presses: []
    };
  },
  computed: {
    gradeOptions() {
      return this.grades.filter((ele) => ele.parentId === this.pressId);
    },
    bookOptions() {
      return this.books.filter((ele) => ele.parentId === this.gradeId);
    },
    volumOptions() {
      return this.volums.filter((ele) => ele.parentId === this.bookId);
    },
    unitOptions() {
      return this.units.filter((ele) => ele.parentId === this.volumId);
    }
  },
  beforeMount() {
    getCategoryList({ subject: this.$store.getters.getLaun })
      .then((list) => {
        this.units = list.filter((ele) => 'unit' === ele.levelCode).sort((a, b) => a.sort - b.sort);
        this.volums = list.filter((ele) => 'material' === ele.levelCode).sort((a, b) => a.sort - b.sort);
        this.books = list
          .filter((ele) => 'book' === ele.levelCode)
          .filter((ele) => this.volums.some((vm) => vm.parentId === ele.id))
          .sort((a, b) => a.sort - b.sort);
        // let
        this.grades = list
          .filter((ele) => 'grade' === ele.levelCode)
          .filter((ele) => this.books.some((bk) => bk.parentId === ele.id))
          .sort((a, b) => a.sort - b.sort);
        this.presses = list
          .filter((ele) => 'press' === ele.levelCode)
          .filter((ele) => this.grades.some((gd) => gd.parentId === ele.id))
          .sort((a, b) => a.sort - b.sort);
      })
      .catch((err) => {
        this.$Message.warning('[后台错误]' + err);
      });
  }
};
</script>

<style scoped>
.item {
  width: 9em;
  margin-right: 1em;
}
</style>